<template>
  <section ref="pc_common" :class="`detail detect_deviece_${deviceType} state_${deviceType}`">
    <HeadBar title="Novel&Genius Online" :deviceType="deviceType" />
    <section v-if="pagenum == 0 && novalInfo" class="page-0 pc_padding">
      <!-- <div v-if="deviceType != 'mobile'" class="book-reading-nav pc_padding">
        <div class="book-reading-nav-item contents cursor" @click="toggleContents">
          Contents
          <img src="@/assets/up.png" :class="{'flip':showContents}" alt="">
        </div>
        <div class="book-reading-nav-item goback cursor" @click="gohome()">
          <img src="@/assets/conents-icon.png" alt="">
          Go to Books
        </div>
      </div> -->
      <div class="detail-recommand-banner-row" @click="fullpageclick(1)">
        <div class="detail-recommand-banner-shadow" />
        <img v-lazy="novalInfo.bigImg" class="detail-recommand-banner-img" alt="">
        <div class="recomannd-banner-rate-info-row">
          <div class="recomannd-banner-rate-info-tag">
            <div class="recomannd-banner-rate-info-tag-item" v-html="novalInfo.novelType" />
          </div>
          <div class="recomannd-banner-rate-info-stars-row">
            <img src="@/assets/star.png" alt=""> {{ novalInfo.novelScore }}/10
          </div>
        </div>
      </div>
      <div class="detail-recommand-title" @click="fullpageclick(1)">
        {{ novalInfo.novelName }}
      </div>
      <AdComponent ref="ads-cover-1" class="content pc_padding" :ads="adsensConfig.cover_1"
        :showDebug="showDebug" />
      <div class="detail-recommand-author" @click="fullpageclick(1)">
        {{ novalInfo.novelAuthorName }}
      </div>
      <div class="detail-recommand-content" @click="fullpageclick(1)"
        v-html="novalInfo.novelStoryIntro" />
      <div class="detail-recommand-btn cursor" @click="gochapter(1)">
        Read Now
      </div>

      <!-- <AdComponent ref="ads-cover-2" class="content pc_padding" :ads="adsensConfig.cover_2"
        :showDebug="showDebug" /> -->

      <div class="nxt-page-btn cursor" @click="gochapter(1)">
        Next Page <img class="arrow" src="@/assets/arror.png" alt="">
      </div>
    </section>
    <section v-if="pagenum != 0 && novalInfo" class="page-n pc_padding">
      <div v-if="deviceType=='mobile' && pagenum!=0" class="page-section prev-page cursor"
        @click="gochapter(parseInt(pagenum)-1)" />
      <div v-if="deviceType=='mobile' && hasNext" class="page-section next-page cursor"
        @click="gochapter(parseInt(pagenum)+1)" />
      <div class="book-reading-nav">
        <div class="book-reading-nav-item contents cursor" @click="toggleContents">
          Contents
          <img src="@/assets/up.png" :class="{'flip':showContents}" alt="">
        </div>
        <div class="book-reading-nav-item goback cursor" @click="gohome()">
          <img src="@/assets/conents-icon.png" alt="">
          Go to Books
        </div>
      </div>

      <div class="book-reading-content-container">
        <transition name="slide-fade">
          <div v-if="showContents" class="contents-menu">
            <ul>
              <li v-for="(item, index) in novalInfo.novelChapter" :key="index" class="cursor"
                :class="{'active':pagenum == index}" @click="gochapter(index)">
                {{ item }}
              </li>
            </ul>
          </div>
        </transition>
        <div v-if="novalDetail && novalDetail.length>0" class="book-reading-content-txt-container">
          <div class="book-reading-content-row">
            <div class="chapter-name" v-html="novalDetail[0].chapterName" />
            <AdComponent ref="ads-content-1" class="content pc_padding"
              :ads="adsensConfig.content_1" :showDebug="showDebug" />
            <div v-for="(data,i) in formattedContent" :key="`novel_${i}`" class="chapter-content"
              v-html="data.content" />
            <AdComponent ref="ads-content-2" class="content pc_padding"
              :ads="adsensConfig.content_2" :showDebug="showDebug" />
          </div>

          <div v-if="deviceType!='mobile'" class="pagenation-row">
            <div v-if="pagenum!=0" class="page-fixed prev-page cursor"
              @click="gochapter(parseInt(pagenum)-1)">
              <img src="@/assets/page-arr.png" alt=""> Prev
            </div>
            <div v-if="hasNext" class="page-fixed next-page cursor"
              @click="gochapter(parseInt(pagenum)+1)">
              <img src="@/assets/page-arr.png" alt=""> Next
            </div>
          </div>
        </div>
      </div>

      <!-- 背景遮挡层 -->
      <div v-if="showContents && deviceType=='mobile'" class="overlay" @click="toggleContents" />
    </section>
    <FootBar v-if="pagenum != 0 && deviceType=='mobile'" :deviceType="deviceType" />
  </section>
</template>

<script>
import { mapGetters } from 'vuex';
import AdComponent from '@/pages/components/AdComponent.vue'
import HeadBar from '@/pages/components/head.vue';
import FootBar from '@/pages/components/footer.vue';
import '@/css/common.scss';
import '@/css/detail.scss'

export default {
  name: 'Detail',
  components: {
    AdComponent,
    HeadBar,
    FootBar
  },
  data () {
    return {
      pagenum: null,
      bookname: null,
      bookid: null,
      showContents: false,
      novalDetail: null,
      novalInfo: null,
      hasNext: false,
    };
  },
  metaInfo () {
    return {
      title: this.novalInfo?.novelName ? `${this.novalInfo.novelName} | Novelgenius.online` : 'Novelgenius.online',
      meta: [
        {
          name: 'description',
          content: this.novalInfo?.novelStoryIntro ? this.novalInfo.novelStoryIntro : '',
        },
      ],
    };
  },
  computed: {
    ...mapGetters([
      'showDebug',
      'deviceType',
      'host',
      "adsensConfig",
      'adPosConfig',
    ]),
    formattedContent () {
      return this.novalDetail.map(chapter => ({
        ...chapter,
        content: chapter.content.replace(/\n/g, '<br>')  // 替换换行符为 <br> 标签
      }));
    }
  },
  created () {
    const { bookname, pagenum, id } = this.$route.params
    console.log(bookname, pagenum, id);
    this.pagenum = pagenum
    this.bookname = bookname
    this.bookid = id

  },
  async activated () {
    console.log(`${this.$options.name} Component activated-----------`);
  },
  async mounted () {
    console.log(`-------------------${this.$options.name} mounted`)
    await this.getDetail(this.pagenum)
    this.loadAdSenseScript();  // 重新加载广告脚本

    // 使用防抖包装滚动事件
    this.onScroll = this.debounce(this.onScroll.bind(this), 300);
    // 监听滚动事件
    window.addEventListener('scroll', this.onScroll);
  },
  deactivated () {
    console.log(`${this.$options.name} Component deactivated-----------`);
  },
  beforeDestroy () {
    console.log(`-------------------${this.$options.name} beforeDestroy`);
    // 组件销毁时移除滚动事件监听
    // window.removeEventListener('scroll', this.onScroll);
  },
  methods: {
    fullpageclick () {
      if (this.deviceType == 'mobile') {
        this.gochapter(1)
      }
    },
    gohome () {
      window.location.href = '/library'
    },
    toggleContents () {
      this.showContents = !this.showContents;
    },
    gochapter (page) {
      console.log(page);
      if (this.pagenum == page) return
      this.pagenum = page
      window.location.href = `/${this.bookname}/${this.bookid}/${page}`
    },
    //shuju
    async getDetail (page) {
      try {
        const res = await this.$global.server.getDetail({
          summaryId: parseInt(this.bookid),
          chapterNum: parseInt(page)
        })
        console.log(res)
        this.novalDetail = res.data.chapterList
        this.novalInfo = res.data.summaryInfo
        this.hasNext = res.data.hasNext
      } catch (err) {
        console.log(err)
      }
    },
    async getTagList () {
      try {
        const res = await this.$global.server.getTagList({ type: '3D' })
        console.log(res)
        this.tagList = res.data
      } catch (err) {
        console.log(err)
      }
    },
    async getImages () {
      try {
        const params = {
          pageNum: this.page,
          pageSize: 30,
          type: '3D'
        };

        // 只有当 this.tag 不为 null 时，才添加 tag 字段
        if (this.activeTag !== null) {
          params.tag = this.activeTag;
        }

        const res = await this.$global.server.getImages(params);
        console.log(res)
        console.log('iiiiiiiiii');
        if (res.code == 200) {
          let data = res.data.photos
          this.images.push(...data)
          this.hasNext = res.data.hasNext
          this.page++;
        }
      } catch (err) {
        console.log(err)
      }
    },
    seclecTag (tag) {
      window.location.href = `/tag/${tag}`
    },
    choosePic (id) {
      window.location.href = `/${id}`
    },
    //pubu
    loadItems () {
      if (this.isLoading) return; // 如果已经在加载，则不重复加载
      this.isLoading = true; // 设置加载状态

      // 模拟异步加载
      setTimeout(() => {
        const newItems = Array.from({ length: 20 }, (_, i) => ({
          src: `https://via.placeholder.com/${i + 1}00x${i + 2}00?text=Item+${i + 1 + this.page * 10}`,
          alt: `Item ${i + 1 + this.page * 10}`,
        }));
        this.items.push(...newItems);
        this.page++;
        this.isLoading = false; // 加载完成后重置加载状态
      }, 500);
    },
    // 防抖函数
    debounce (func, delay) {
      let timeout;
      return function (...args) {
        clearTimeout(timeout);
        timeout = setTimeout(() => {
          func.apply(this, args);
        }, delay);
      };
    },
    onScroll () {
      // 获取文档的总高度
      const documentHeight = document.documentElement.scrollHeight;
      // 当前滚动的高度
      const scrollTop = window.scrollY || document.documentElement.scrollTop;
      // 视口的高度
      const windowHeight = window.innerHeight;

      // 当滚动距离接近底部2000px时触发
      if (scrollTop + windowHeight >= documentHeight - 2000) {
        console.log('加载');
        // this.getImages();
      }
    },
    //广告相关start
    shouldInsertAd (index) {
      // 判断是否应该插入广告的位置
      const position = index + 1;
      const adIndex = this.adPosConfig.home.indexOf(position);

      /// 返回广告在 homeAD 中的索引，如果不在配置中则返回 -1
      return adIndex !== -1 ? adIndex : -1;
    },
    shouldDetailInsertAd (index) {
      // 判断是否应该插入广告的位置
      const position = index + 1;
      const adIndex = this.adPosConfig.detail.indexOf(position);

      /// 返回广告在 homeAD 中的索引，如果不在配置中则返回 -1
      return adIndex !== -1 ? adIndex : -1;
    },
    async loadAdSenseScript () {
      // 其他需要在初次加载时执行的操作
      // 先检查广告是否已经加载
      if (window.adsbygoogle && window.adsbygoogle.loaded) { // 检查广告是否已经加载
        console.log("Adsense script already loaded.");
        this.$eventrack('adscript_loaded', 'expose');
        this.observeAds()
        return; // 如果已加载，直接返回
      }
      await this.loadScriptConditionally();
    },
    loadScriptConditionally () {
      console.log(this.adsensConfig);
      // 判断广告脚本URL是否存在
      if (!this.adsensConfig?.scriptUrl) {
        console.log('广告脚本的URL不存在,终止加载广告外链');
        this.$eventrack('no_adscript_config', 'expose');
        return;
      }
      // 检查该脚本是否已经被添加
      const existingScript = document.querySelector(`script[src="${this.adsensConfig.scriptUrl}"]`);
      if (existingScript) {
        this.$eventrack('adscript_exist', 'expose');
        console.log('脚本已存在，无需重新添加');
        this.observeAds('ads-'); // 监听广告元素进入可视区域
        return;
      }

      console.log('准备插入脚本');
      const script = document.createElement('script');
      script.src = this.adsensConfig?.scriptUrl;
      script.crossOrigin = 'anonymous';
      script.async = true;

      const header = document.getElementsByTagName('head')[0];
      header.appendChild(script);

      this.$eventrack('adscript_add_success', 'expose');
      // script.onload = this.loadAdWithDelay.bind(this); // 使用 bind 确保 this 指向正确
      script.onload = this.observeAds.bind(this); // 使用 bind 确保 this 指向正确
      console.log('脚本插入完成,加载完成,执行加载插入广告及监听操作');

    },
    observeAds () {
      const adsElements = Object.entries(this.$refs)
        .filter(([key]) => key.startsWith('ads-'))
        .flatMap(([, ref]) => ref);

      const observer = new IntersectionObserver((entries) => {
        entries.forEach(entry => {
          if (entry.isIntersecting) {
            // 当广告元素进入可视区域时加载广告
            console.log('Ad element is in view:', entry.target);
            this.loadAdWithDelay(entry.target);
            observer.unobserve(entry.target); // 加载后停止观察该广告元素
          }
        });
      });
      console.log(adsElements);

      adsElements.forEach(ad => {
        const adElement = ad instanceof HTMLElement ? ad : ad.$el;  // 如果是 Vue 实例，获取它的 $el
        if (adElement) {
          observer.observe(adElement); // 开始监听广告元素是否进入可视区域
          console.log('Observing ad element:', adElement);
        }
      });
    },
    loadAdWithDelay (adElement) {
      // console.log('7897988979879');
      // console.log(adElement);
      setTimeout(() => {
        this.displayAd(adElement);
      }, 1500);
    },

    async displayAd (adElement) {
      await this.$nextTick();

      if (!window.adsbygoogle || !window.adsbygoogle.loaded) {
        console.log("Adsense script not loaded yet, delaying ad display.");
        setTimeout(() => this.displayAd(adElement), 500); // 延迟再次尝试
        return;
      }

      console.log('Ad element is ready for push:', adElement);
      if (adElement.offsetWidth > 0) {
        console.log('ready to push');
        (window.adsbygoogle = window.adsbygoogle || []).push({});
      } else {
        console.log('Ad element has no size, skipping.');
      }
    },
    //广告相关end

    //ciyi

    goto (name) {
      this.$router.push({ name: name });
    },

  }
};
</script>
